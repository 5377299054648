.toolbar {
  display: flex;
  align-items: center;
  padding: 3px 5px;
  -webkit-transform: translate(-50%) scale(0);
  transform: translate(-50%) scale(0);
  position: absolute;
  border: solid 0.8px #d1d1d1;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  z-index: 2;
  box-sizing: border-box;
 
}

.toolbar:after, .toolbar:before {
  content: none;
}
