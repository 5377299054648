.toolbar {
  margin-top: 4px;
  position: sticky;
  top:0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 3px 5px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);;
  z-index: 2;
  
}

.toolbar:after, .toolbar:before {
  content: none;
}
