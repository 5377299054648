.editor {
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  margin-bottom: 2em;
  box-shadow: inset 0px 1px 8px -3px #ababab;
  background: #fefefe;
}

.editor :global(.public-DraftEditor-content) {
  min-height: 140px;
}

.DraftEditor-header {

  overflow-wrap: break-word;
}
.DraftEditor-header-placeholder {
  position: relative;
}

.DraftEditor-header-placeholder::before {
  content:'Type here...';
  position: absolute;
  bottom: 0;
  color: #CED3E0;
  pointer-events: none;
}
.DraftEditor-unstyled-placeholder::before {
  content:'Type description here...';
  position: absolute;
  color: #CED3E0;
  pointer-events: none;
}


.headlineButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  color: #355874;
  font-size: 20px;
  border: 0;
  padding-top: 0px;
  vertical-align: bottom;
  height: 37px;
  width: 41px;
}

.headlineButton:hover,
.headlineButton:focus {
  background: #138BFF;
  color: #ffffff;
  box-shadow: 0 1px 0 0 #0f873e;
  border-radius: 4px;
  fill: #ffffff;
}

.draftJsToolbar__button__qi1gf {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  color: #355874;
  font-size: 18px;
  border: 0;
  padding-top: 0px;
  vertical-align: bottom;
  height: 37px;
  width: 41px;
}

.draftJsToolbar__button__qi1gf svg {
  fill: #355874;
  height: 15px;
}

.draftJsToolbar__button__qi1gf svg:hover,
.draftJsToolbar__button__qi1gf svg:focus {
  fill: #ffffff;
}

.draftJsToolbar__button__qi1gf:hover,
.draftJsToolbar__button__qi1gf:focus {
  background: #138BFF;
  color: #ffffff;
  border-radius: 4px;
  outline: 0; /* reset for :focus */
}

.draftJsToolbar__button__qi1gf:hover > * {
  fill: #ffffff;
}

.draftJsToolbar__active__3qcpF {
  background: #138BFF;
  color: #ffffff;
  box-shadow: 0 1px 0 0 #0f873e;
  border-radius: 4px;
}

.draftJsToolbar__active__3qcpF svg {
  fill: #ffffff;
}
.draftJsToolbar__separator__3U7qt {
  display: inline-block;
  border-right: 1px solid #ddd;
  height: 24px;
  margin: 0 0.5em;
}
.draftJsToolbar__toolbar__dNtBH {
  display: flex;
  align-items: center;
  padding: 3px 5px;
  -webkit-transform: translate(-50%) scale(0);
  transform: translate(-50%) scale(0);
  position: absolute;
  border: solid 0.8px #d1d1d1;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  z-index: 2;
  box-sizing: border-box;
  margin-left: 175px;
}

.draftJsToolbar__toolbar__dNtBH:after,
.draftJsToolbar__toolbar__dNtBH:before {
  content: none;
}

.draftJsToolbar__buttonWrapper__1Dmqh {
  padding: 2px;
}

.public-DraftEditor-content {
  outline: none;
    user-select: text;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  
}

.public-DraftStyleDefault-block span {
  border-left: solid thin transparent;
}