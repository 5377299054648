.buttonWrapper {
  display: inline-block;
  padding: 2px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  color: #355874;
  font-size: 18px;
  border: 0;
  padding-top: 0px;
  vertical-align: bottom;
  height: 37px;
  width: 41px;
}

.button svg {
  fill: #355874;
  height: 15px;
}

.button:hover, .button:focus {
  background: #138BFF;
  color: #ffffff;
  border-radius: 4px;
  outline: 0; /* reset for :focus */
}

.button:hover > * {
  fill: #ffffff;
}

.active {
  background: #138BFF;
  color: #ffffff;
  box-shadow: 0 1px 0 0 #0f873e;
  border-radius: 4px;
}

.active svg {
  fill: #ffffff;
}