.react-datepicker {
  background-color: white;
  /* position: absolute !important; */
  top: 0;
  right: 0;
  font-family: "Lato";
  border: none;
}

.react-datepicker__month-container {
  border: none;
}
.react-datepicker__header {
  background-color: white;
  border: none;
}

.react-datepicker__current-month {
  color: #355874;
}
.react-datepicker__day-name {
  color: #92a4b7;
}
.react-datepicker__day {
  color: #0d1424;
}
.react-datepicker__day--weekend {
  color: #0d1424;
}
.react-datepicker__day--selected {
  color: white;
  background-color: #0a85e7;
  border-radius: 50%;
}
.react-datepicker__day--keyboard-selected {
  color: white;
}
.react-datepicker__day--selected:focus {
  outline: none;
}
.react-datepicker__day--disabled {
  color: #92a4b7;
}
.react-datepicker__day--in-range {
  color: white;
}
